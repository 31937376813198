// Fonts
/* Mono Regular */
@font-face {
  font-family: 'IBM Plex Mono';
  src: url('../fonts/IBMPlexMono-Regular.woff2') format('woff2'),
  url('../fonts/IBMPlexMono-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

/* Regular */
@font-face {
  font-family: 'IBM Plex Sans';
  src: url('../fonts/IBMPlexSans-Regular.ttf') format('ttf');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'IBM Plex Sans';
  src: url('../fonts/IBMPlexSans-Thin.ttf') format('ttf');
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'IBM Plex Sans';
  src: url('../fonts/IBMPlexSans-ExtraLight.ttf') format('ttf');
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

/* Bold */
@font-face {
  font-family: 'IBM Plex Sans';
  src: url('../fonts/IBMPlexSans-Bold.ttf') format('ttf');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

/* Italic */
@font-face {
  font-family: 'IBM Plex Sans';
  src: url('../fonts/IBMPlexSans-Italic.ttf') format('ttf');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

/* Bold Italic */
@font-face {
  font-family: 'IBM Plex Sans';
  src: url('../fonts/IBMPlexSans-BoldItalic.ttf') format('ttf');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

/* Title */
@font-face {
  font-family: 'TTFirsNeue-Regulart';
  src: url('../fonts/TTFirsNeue-Regular.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

// Variables
@import 'variables';

// Bootstrap
//@import 'bootstrap-5.2.3/scss/bootstrap';
@import 'bootstrap/scss/bootstrap';

.product-title {
  text-transform: none;
  font-family: $font-family-logo;
}

body {
    margin: 0;
    min-height: 100vh;
    overflow: hidden;
}

#outer {
  height: 100vh;
}

canvas {
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
}

.text-body {
    font-size: 1rem;
}

//p {
//    span {
//        color: #666;
//    }
//}

a {
    color: #666;
    //color: #fff;
    text-decoration: none;

    &:hover {
        color: #fff;
        text-decoration: underline;
    }
}

header {
  font-family: $font-family-monospace
  //text-transform: uppercase;
}

main{
  overflow-y: auto;
  font-weight: 200;
}

footer{
  font-family: $font-family-monospace
}

#hero {
    text-transform: uppercase;
    color: #fff;

    .container {
        height: auto;
    }

    .row {
        overflow: hidden;

        .col-lg-6 {
            transform: translateY(100%);
            transition: all .9s ease-in-out;
            transition-delay: 1200ms;

            @include media-breakpoint-down(lg) {
                transform: translateY(0%);
            }
        }
    }

    svg {
        width: 100%;
        height: auto;
        overflow: hidden;

        path {
            transform: translateY(100%);
            transition: all .6s ease-in-out;

            &:nth-child(1) {
                transition-delay: 900ms;
            }

            &:nth-child(2) {
                transition-delay: 800ms;
            }

            &:nth-child(3) {
                transition-delay: 700ms;
            }

            &:nth-child(4) {
                transition-delay: 600ms;
            }

            &:nth-child(5) {
                transition-delay: 500ms;
            }

            &:nth-child(6) {
                transition-delay: 400ms;
            }

            &:nth-child(7) {
                transition-delay: 300ms;
            }
        }
    }
}

//body {
//    &.page-loaded {
//        #hero {
//
//            .row {
//                .col-lg-6 {
//                    transform: translateY(0);
//                }
//            }
//
//            svg {
//                path {
//                    transform: translateY(0);
//                }
//            }
//        }
//    }
//}

#hero.page-loaded {

  .col-lg-12 {
    transform: translateY(0);
  }

  svg {
    path {
      transform: translateY(0);
    }
  }
}

.vanta-body-background {
  position: absolute;
  height: 100vh;
  top: 0; // Align to the top of the viewport
  left: 0; // Align to the left of the viewport
  width: 100%; // Cover the full width
  z-index: -1; // Ensure it's behind other content
  //background-size: cover; // Ensure the background covers the fixed area
}

.navbar-nav > li > .dropdown-menu { background-color: $text-background; }

// make background color apply at inner nodes margins (no collapse). Maybe only apply to .text-area ?!
.text-area::before {
  content: "";
  display: table; /* or block depending on your needs */
}

// make background color apply at inner nodes margins (no collapse). Maybe only apply to .text-area ?!
.text-area::after {
  content: "";
  display: table; /* or block depending on your needs */
}

.text-area {
  //background-color: rgba(30, 30, 30, 1);
  background-color: var(--bs-body-bg);
  //background-color: #141414;
  //padding: 0.8rem 0rem 0.8rem 0rem;
}

[data-bs-theme=dark] {
  //  color-scheme: dark;
  //  --bs-body-color: #dee2e6;
  //  --bs-body-color-rgb: 222, 226, 230;
  //  //--bs-body-bg: #212529;
  //  --bs-body-bg: #2e2e2f;
  //--bs-body-bg: #000;
  --bs-body-bg: #{$text-background};
  //--bs-body-bg: #141414;
  //  --bs-body-bg-rgb: 33, 37, 41;
  //  --bs-emphasis-color: #fff;
  //  --bs-emphasis-color-rgb: 255, 255, 255;
  //  --bs-secondary-color: rgba(222, 226, 230, 0.75);
  //  --bs-secondary-color-rgb: 222, 226, 230;
  //  --bs-secondary-bg: #343a40;
  //  --bs-secondary-bg-rgb: 52, 58, 64;
  //  --bs-tertiary-color: rgba(222, 226, 230, 0.5);
  //  --bs-tertiary-color-rgb: 222, 226, 230;
  //  --bs-tertiary-bg: #2b3035;
  //  --bs-tertiary-bg-rgb: 43, 48, 53;
  //  --bs-primary-text-emphasis: #6ea8fe;
  //  --bs-secondary-text-emphasis: #a7acb1;
  //  --bs-success-text-emphasis: #75b798;
  //  --bs-info-text-emphasis: #6edff6;
  //  --bs-warning-text-emphasis: #ffda6a;
  //  --bs-danger-text-emphasis: #ea868f;
  //  --bs-light-text-emphasis: #f8f9fa;
  //  --bs-dark-text-emphasis: #dee2e6;
  //  --bs-primary-bg-subtle: #031633;
  //  --bs-secondary-bg-subtle: #161719;
  //  --bs-success-bg-subtle: #051b11;
  //  --bs-info-bg-subtle: #032830;
  //  --bs-warning-bg-subtle: #332701;
  //  --bs-danger-bg-subtle: #2c0b0e;
  //  --bs-light-bg-subtle: #343a40;
  //  --bs-dark-bg-subtle: #1a1d20;
  //  --bs-primary-border-subtle: #084298;
  //  --bs-secondary-border-subtle: #41464b;
  //  --bs-success-border-subtle: #0f5132;
  //  --bs-info-border-subtle: #087990;
  //  --bs-warning-border-subtle: #997404;
  //  --bs-danger-border-subtle: #842029;
  //  --bs-light-border-subtle: #495057;
  //  --bs-dark-border-subtle: #343a40;
  //  --bs-heading-color: inherit;
  //  --bs-link-color: #6ea8fe;
  //  --bs-link-hover-color: #8bb9fe;
  //  --bs-link-color-rgb: 110, 168, 254;
  //  --bs-link-hover-color-rgb: 139, 185, 254;
  //  --bs-code-color: #e685b5;
  //  --bs-highlight-color: #dee2e6;
  //  --bs-highlight-bg: #664d03;
  //  --bs-border-color: #495057;
  //  --bs-border-color-translucent: rgba(255, 255, 255, 0.15);
  //  --bs-form-valid-color: #75b798;
  //  --bs-form-valid-border-color: #75b798;
  //  --bs-form-invalid-color: #ea868f;
  //  --bs-form-invalid-border-color: #ea868f;
}
