// Body
$body-bg:        #000 !default;
$primary:        #fff !default;
$body-color:    $primary !default;
$text-background: #141414 !default;

// Typography
//$font-family-sans-serif: 'IBM Plex Mono', sans-serif;
// Typography
$font-family-sans-serif: 'IBM Plex Sans', sans-serif;
$font-family-monospace: 'IBM Plex Mono', monospace;
$font-family-logo: 'TTFirsNeue-Regular', $font-family-sans-serif;
$font-size-base: 1rem;
$line-height-base: 1.6;

$enable-rfs: false !default;
$enable-rounded: false !default;
$enable-shadows: false !default;

$container-max-widths: (xxl: 1500px) !default;
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1560px
) !default;
